/* eslint-disable global-require */
<template>
  <div class="contact">
    <img :src="require('@/assets/images/tourismeBenin/creds.jpg')" alt="" class="back-image" />
    <div class="filigrane-color" />
    <div class="contact-wrapper">
      <!-- <div class="left-infos">
        <h1>Vous avez une question ?</h1>
        <p>
          Merci de nous contacter en écrivant à cette adresse email:
          mtca.dsisa@gouv.bj. <br />
          Nous mettons tout en oeuvre pour répondre le plus rapidement possible.
        </p>
        <h3>
          Heures d'Ouverture : <br />
          Lundi - Vendredi 8h - 17h30
        </h3>
      </div> -->
      <div class="contact-form">
        <!-- <router-link to="/" class="logo mx-auto">
          <img
            :src="require('@/assets/images/logo/logo_mtca.png')"
            alt="logo"
          />
        </router-link> -->
        <h1>{{ $t('contactPage.title') }}</h1>
        <div class="text-center mb-2">
          <h4>{{ $t('contactPage.entity') }}</h4>
          <span>01 BP 2037, Cotonou - BENIN</span><br />
          <span>+229 21307013</span><br />

          <a href="mailto:mtca.contact@gouv.bj" class="text-primary" style="text-transform: underline">mtca.dsisa@gouv.bj
          </a>
        </div>
        <validation-observer ref="formRules">
          <div class="contact-names-email">
            <validation-provider v-slot="{ errors }" rules="required">
              <b-form-group class="w-100" :label="$t('contactPage.names')" label-for="names">
                <template v-slot:label>
                  <span>{{ $t('contactPage.names') }}
                    <RequiredStar />
                  </span>
                </template>
                <b-form-input :placeholder="$t('contactPage.namesPlaceholder')" id="names"
                  v-model="objectMsg.nomPrenoms" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider v-slot="{ errors }" rules="required|email">
              <b-form-group class="w-100" :label="$t('contactPage.mail')" label-for="email">
                <template v-slot:label>
                  <span>{{ $t('contactPage.mail') }}
                    <RequiredStar />
                  </span>
                </template>
                <b-form-input :placeholder="$t('contactPage.mailPlaceholder')" id="email" v-model="objectMsg.mail" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <validation-provider v-slot="{ errors }" rules="required">
            <b-form-group :label="$t('contactPage.object')" label-for="Objet">
              <template v-slot:label>
                <span>{{ $t('contactPage.object') }}
                  <RequiredStar />
                </span>
              </template>
              <b-form-input :placeholder="$t('contactPage.objectPlaceholder')" id="Objet" v-model="objectMsg.sujet" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <div class="mt-1">
            <validation-provider v-slot="{ errors }" rules="required">
              <b-form-group :label="$t('contactPage.message')" label-for="message">
                <template v-slot:label>
                  <span>{{ $t('contactPage.message') }}
                    <RequiredStar />
                  </span>
                </template>
                <b-form-textarea id="message" :placeholder="$t('contactPage.messagePlaceholder')"
                  v-model="objectMsg.message" class="w-100" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="mt-2 d-flex justify-content-center">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="d-flex align-items-center"
              @click="envoyer()" :disabled="isSending">
              <b-spinner v-if="isSending" small style="margin-right: 5px" />
              {{ $t('button.send') }}
            </b-button>
          </div>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BSpinner,
  BCardText,
  BInputGroupPrepend,
  BButton,
  BCardHeader,
  BFormTextarea,
} from 'bootstrap-vue';

import utilsService from '@/services/utils/utils.service';

import TipsModalCreateFromLastRecord from '@/components/TipsModalCreateFromLastRecord.vue';
import localstorageService from '@/services/localstorage/localstorage.service';
import { mapMutations, mapActions } from 'vuex';
import vSelect from 'vue-select';
import locale from '@core/layouts/components/app-navbar/components/Locale.vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { quillEditor } from 'vue-quill-editor';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
// const kb = utilsService.getListFormulaires()
// --------------------------------------------------
import paramsStoreModule from '@/store/params';
import RequiredStar from "@/components/RequiredStar.vue";

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BSpinner,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BCardHeader,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,

    TipsModalCreateFromLastRecord,
    vSelect,
    quillEditor,
    locale,
    RequiredStar,
  },
  directives: {
    Ripple,
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Description de votre message',
    };
    // const formulairesByGroup = utilsService.getListFormulairesByGroup();
    // const appLocales = [
    //   { code: 'en', name: 'anglais' },
    //   { code: 'fr', name: 'francais' },
    // ];

    const requiredStoreModules = [
      { path: 'params', module: paramsStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      isSending: false,
      objectMsg: {
        nomPrenoms: '',
        mail: '',
        sujet: '',
        message: '',
        token: '',
      },
      optionsSujet: ["Problèmes d'utilisation", 'Suggestions', 'Autres'],
    };
  },

  computed: {
    // ...mapState("auth", {
    //   isAuthenticated: "isAuthenticated",
    // }),
    // filteredKB() {
    //   const knowledgeBaseSearchQueryLower =
    //     this.knowledgeBaseSearchQuery.toLowerCase();
    //   // if(this.selectedSectorId){ // filtre choix de secteur
    //   return this.kb.filter((item) => {
    //     const condition =
    //       (this.selectedSectorId
    //         ? item.sector == this.selectedSectorId
    //         : true) &&
    //       item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower);
    //     return condition;
    //   });
    // },
    // currentLocaleName() {
    //   if (this.$i18n.locale === "fr") return "francais";
    //   return "anglais";
    // },
  },
  watch: {
    // selectedSector: {
    //   handler(val, old) {
    //     console.log("home ---> selectedSector: ", val, old);
    //     this.selectedSectorId = this.selectedSector._id
    //       ? this.selectedSector._id
    //       : null;
    //     this.filteredKB;
    //   },
    // },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {
    // console.log(this.formulairesByGroup);
    if (localstorageService.getUserId()) {
      this.kbOptions = { publicOnly: false, privateOnly: true };
    } else {
      this.kbOptions = { publicOnly: true, privateOnly: false };
    }
    // this.loadSectors();
  },
  methods: {
    ...mapActions('params', {
      action_sendMailNousContacter: 'sendMailNousContacter',
    }),
    // ---------------------------------------------------------
    envoyer() {
      this.$refs.formRules.validate().then(async (success) => {
        if (success) {
          this.isSending = true;
          console.log('this.objectMsg: 🩸🩸🩸 ', this.objectMsg);
          // ReCaptcha v3
          // (optional) Wait until recaptcha has been loaded.
          await this.$recaptchaLoaded();
          // Execute reCAPTCHA with action "login".
          const token = await this.$recaptcha('login');
          if (!token) {
            this.isLoading = false;
            return;
          }
          const payload = {
            fullname: this.objectMsg.nomPrenoms,
            email: this.objectMsg.mail,
            subject: this.objectMsg.sujet,
            content: this.objectMsg.message,
            contact: this.objectMsg.contact,
            token,
          };

          console.log('PAYLOAD: 🔰🔰🔰 ', payload);
          this.action_sendMailNousContacter(payload)
            .then((res) => {
              console.log('res: ', res);
              if (res.status === 201) {
                this.objectMsg = {
                  nomPrenoms: '',
                  mail: '',
                  sujet: '',
                  message: '',
                  token: '',
                };

                // success alert
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opération réussie',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                });
                this.isSending = false;

                // redition to home
                this.$router.replace({ name: 'home-public' });
              }
            })
            .catch((err) => {
              console.log('err: ', err);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message || 'Une erreur est survenue',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              });
              this.isSending = false;
            });
        }
      });
    },
    // loadSectors() {
    //   const currentParams = {
    //     page: this.paginationData.currentPage - 1,
    //     perPage: this.paginationData.perPage,
    //   };
    //   this.fetchSecteurs({ params: currentParams || null })
    //     .then((res) => {
    //       this.sectors = res.data.secteurs;
    //       this.sectorsTotal = res.data.total;
    //       this.sectors.forEach((currentValue, index, arr) => {
    //         this.sectorsCustom.push({
    //           nom: currentValue.nom,
    //           _id: currentValue._id,
    //         });
    //       });
    //       // console.log('res this.sectorsCustom: ', this.sectorsCustom)
    //     })
    //     .catch((err) => {
    //       this.isLoadingTableData = false;
    //       console.log(err);
    //     });
    // },
  },
};
</script>

<style scoped>
.contact {
  position: relative;
  width: 100%;
  /* min-height: 100vh; */
}

.contact-wrapper {
  margin: 0 auto;
  max-width: 1300px;
  height: 100%;
  /* min-height: 100vh; */

  padding: 3em 1em;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 3em;
}

.contact .back-image {
  position: absolute;
  inset: 0;
  z-index: -20;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact .filigrane-color {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -10;

  background-color: #06250fb2;
}

.left-infos {
  color: #fff;
  text-shadow: 2px 2px 1px #00000090;
}

.left-infos h1 {
  color: #fff;
  font-weight: bold;
  font-size: 35px;
}

.left-infos h3 {
  color: #fff;
}

.left-infos p {
  margin: 1em 0;
  line-height: 30px;
  color: #fff;
  font-size: 16px;
}

.contact-form {
  border-radius: 1em;
  padding: 2em;
  background-color: #fff;
  min-width: 330px;
}

.contact-form .logo {
  max-width: 190px;
  display: block;
}

.contact-form .logo img {
  width: 190px;
}

.contact-form p {
  margin: 1em auto;
  font-size: 20px;
  text-align: center;
}

.contact-form h1 {
  margin: 0.3em auto 0.7em auto;
  font-size: 27px;
  text-align: center;
}

.contact-names-email {
  display: grid;
  gap: 0 2em;
}

/* MEDIA QUERIES */
@media (min-width: 576px) {
  .contact-wrapper {
    flex-direction: row;
    padding: 4em 3em;
  }

  .contact-form {
    padding: 3em;
  }

  .contact-form {
    width: 100%;
    max-width: 650px;
  }

  .left-infos {
    width: 55%;
  }

  .contact-names-email {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {}

@media (min-width: 992px) {
  .left-infos h1 {
    font-size: 55px;
  }

  .left-infos p {
    font-size: 25px;
  }
}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}
</style>
